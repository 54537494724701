export const CustomPagination = ({ setCurrentPage, current, total }) => {
  const handlePagination = (currentPage) => {
    if (currentPage > total || currentPage < 1) {
      return;
    }
    setCurrentPage(currentPage);
  };

  const getPageNumbers = () => {
    const delta = 2;
    const range = [];
    const rangeWithDots = [];

    for (
      let i = Math.max(2, current - delta);
      i <= Math.min(total - 1, current + delta);
      i++
    ) {
      range.push(i);
    }

    if (current - delta > 2) {
      rangeWithDots.push(1, '...');
    } else {
      rangeWithDots.push(1);
    }

    rangeWithDots.push(...range);

    if (current + delta < total - 1) {
      rangeWithDots.push('...', total);
    } else if (total > 1) {
      rangeWithDots.push(total);
    }

    return rangeWithDots;
  };

  const renderPageNumber = (pageNum, index) => {
    if (pageNum === '...') {
      return (
        <li key={`dots-${index}`} className="px-3">
          <span className="text-gray-400">...</span>
        </li>
      );
    }

    return (
      <li
        onClick={() => handlePagination(pageNum)}
        key={pageNum}
        className={`cursor-pointer px-3 py-2 text-sm
          ${current === pageNum 
            ? "border border-gray-300 bg-white" 
            : "text-gray-600 hover:bg-gray-50"
          }
        `}
        role="button"
        aria-label={`Page ${pageNum}`}
        aria-current={current === pageNum ? "page" : undefined}
      >
        {pageNum}
      </li>
    );
  };

  const renderNavigationButton = (type) => {
    const isDisabled = type === 'previous' ? current === 1 : current === total;
    const onClick = () => handlePagination(type === 'previous' ? current - 1 : current + 1);
    
    return (
      <button
        onClick={onClick}
        disabled={isDisabled}
        className={`flex items-center gap-1 px-4 py-2 text-sm
          ${isDisabled 
            ? "text-gray-300 cursor-not-allowed" 
            : "text-gray-600 hover:bg-gray-50"
          }
        `}
        aria-label={`${type} page`}
      >
        {type === 'previous' && (
          <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
          </svg>
        )}
        {type === 'previous' ? 'Previous' : 'Next'}
        {type === 'next' && (
          <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        )}
      </button>
    );
  };

  if (total <= 1) return null;

  return (
    <nav aria-label="Pagination" className="inline-flex items-center divide-x border border-gray-300 rounded-lg bg-white shadow-sm">
      {renderNavigationButton('previous')}
      <ul className="flex items-center divide-x">
        {getPageNumbers().map((pageNum, index) => renderPageNumber(pageNum, index))}
      </ul>
      {renderNavigationButton('next')}
    </nav>
  );
};
