import { useEffect, useState } from "react";

// components
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import Spinner from "../../components/Common/Spinner";
import UserForm from "../../components/User/UserForm";

export default function User({ allPermission }) {
    const [isEdit, setIsEdit] = useState(false);
    const [token, setToken] = useState();
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [empId, setEmpId] = useState("");
    const [mobile, setMobile] = useState("");
    const [roleId, setRoleId] = useState();
    const [userList, setUserList] = useState([]);
    const [allRole, setAllRole] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [userId, setUserId] = useState();
    const [links, setLinks] = useState([]);
    const [userLoading, setUserLoading] = useState(false);

    let server = localStorage.getItem("journalServer");
    let baseUrl;
    if (server == "match") {
        baseUrl = process.env.REACT_APP_MATCHTRADER_BASE_URL;
    } else if (server == "cdemo") {
        baseUrl = process.env.REACT_APP_CTRADER_DEMO_BASE_URL;
    } else if (server == "clive") {
        baseUrl = process.env.REACT_APP_CTRADER_LIVE_BASE_URL;
    } 

    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState);
        if (isEdit) {
            setUserId();
            setName("");
            setEmail("");
            setEmpId("");
            setIsEdit(false);
        }
    };

    const handleEdit = (user) => {
        setName(user?.user_name);
        setEmail(user?.login);
        toggleDrawer();
        setIsEdit(true);
    };

    const handleDelete=(user)=>{
        setName(user?.user_name);
        setEmail(user?.login);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setUserLoading(true);

        const body = JSON.stringify({
            full_name: name,
            email: email,
            password: password,
        });

        var requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json", // Specify the content type
                Authorization: `${token}`,
            },
            body: body,
        };

        try {
            let res = await fetch(
                isEdit
                    ? `${baseUrl}/users/user_update`
                    : `${baseUrl}/user_create`,
                requestOptions
            );

            const data = await res.json();

            if (res?.status !== 400) {
                getAllUser(`${baseUrl}/user_list`);
                toggleDrawer();
                toast(data?.message);
            } else if (res?.status === 400) {
                for (var key of Object.keys(data?.data)) {
                    toast(`${data?.data[key]}`);
                }
            }
            setUserLoading(false);
        } catch (error) {
            console.error(error);
        }
    };


    const getAllUser = async (url) => {
        let response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: `${token}`,
            },
        });
        const result = await response.json();
        setUserList(result?.data);
        setLinks(result?.links);
    };

    const getRole = () => {
        fetch(`${baseUrl}/role-permission/get-roles`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => setAllRole(data?.data?.roles));
    };

    useEffect(() => {
        let token = localStorage.getItem("token");
        setToken(token);
    }, []);

    useEffect(() => {
        token && getAllUser(`${baseUrl}/user_list?page=${1}&rows=1000`);
        //token && getRole();
    }, [token]);

    if (userList?.length === 0) return <Spinner />;

    return (
        <>
            <div className="w-full">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                    <div className="rounded-t mb-0  py-3">
                        <div className="flex flex-wrap items-center">
                            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                <h3 className="text-2xl font-medium text-[rgba(0, 0, 0, 0.8)]">
                                    User List
                                </h3>
                            </div>
                            <div className="">
                                <button
                                    onClick={toggleDrawer}
                                    className="bg-indigo-500 flex items-center justify-between w-[160px] text-white active:bg-indigo-600  text-base font-semibold  px-4 py-[10px] rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                >
                                    <AddIcon />
                                    <span>Create User</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="w-full overflow-x-auto mt-2 shadow-[0px_4px_45px_#F3F3F3]">
                        <table className="w-full text-left border-collapse">
                            <thead>
                                <tr className="mx-8 border-b text-[#0F223C] text-sm font-semibold whitespace-nowrap">
                                    <th className="py-[23px] pl-5">Name</th>
                                    <th className="py-[23px]">Email</th>
                                    <th className="py-[23px]">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {userList?.map((user, idx) => {
                                    return (
                                        <tr
                                            key={idx}
                                            className="border-b  border-[#EDEFF3] whitespace-nowrap text-sm hover:bg-gray-200"
                                        >
                                            <td className="py-7 pl-5">{user?.user_name}</td>
                                            <td className="py-7">{user?.login}</td>
                                            <td className="py-7 pr-5">
                                                <button
                                                    onClick={(e) => handleEdit(user)}
                                                    className="text-white bg-[#0D86FF] rounded m-1 px-2 py-1"
                                                >
                                                    Edit
                                                </button>

                                                {/* <button className="text-white bg-[#E03232] rounded m-1 px-2 py-1">
                                                    Delete
                                                </button> */}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>

                <UserForm
                    isEdit={isEdit}
                    drawerName={isEdit ? "Update User" : "Create User"}
                    name={name}
                    email={email}
                    password={password}
                    isOpen={isOpen}
                    toggleDrawer={toggleDrawer}
                    handleSubmit={handleSubmit}
                    setName={setName}
                    setEmail={setEmail}
                    setPassword={setPassword}
                />
            </div>
        </>
    );
}
